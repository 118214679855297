import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Get } from "../Api/api";
import { userRoutes } from "../Routes/UserRoutes";
import { transformProducts } from "../Supporting files/HelpingFunction";
import Footer from "../components/Footer";
import Loader from "../components/Loader/Loader";
import ProductCard from "../components/ProductCard";
import ProductsList from "../components/ProductsList";

const ProductsData = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categoryId = searchParams.get("categoryId");
  const [value, setValue] = useState([0, 5000]); // Price filter
  const [productStats, setProductStats] = useState([]); // Stats like categories, colors, max price
  const [checkedCategories, setCheckedCategories] = useState(
    categoryId ? [categoryId] : []
  ); // Initialize with categoryId
  const [selectedColors, setSelectedColors] = useState([]); // Color filter
  const [allProducts, setAllProducts] = useState([]); // Products to display
  const [isLoader, setIsLoader] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true); // To avoid triggering API on first render
  const [openFilter, setOpenFilter] = useState(false);
  const toggleDrawer = () => setOpenFilter(!openFilter);
  // Handle category checkbox change
  const handleCheckboxChange = (id) => {
    setCheckedCategories((prevCheckedCategories) =>
      prevCheckedCategories.includes(id)
        ? prevCheckedCategories.filter((categoryId) => categoryId !== id)
        : [...prevCheckedCategories, id]
    );
  };

  // Handle color selection
  const handleColorClick = (color) => {
    setSelectedColors((prevSelectedColors) =>
      prevSelectedColors.includes(color)
        ? prevSelectedColors.filter((selectedColor) => selectedColor !== color)
        : [...prevSelectedColors, color]
    );
  };

  // Handle price range change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Fetch product stats (categories, colors, max price)
  const GetProductStates = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getProductState);
      setValue([0, response.response.maxPrice]); // Set price range
      setProductStats(response.response); // Set categories and colors
    } catch (error) {
      console.error("Error fetching product states:", error.message);
    }
  };

  // Fetch filtered products
  const GetAllProduct = async () => {
    try {
      setIsLoader(true);
      const categories = checkedCategories.join(",");
      const colors = selectedColors.map(encodeURIComponent).join(",");
      const min = value[0];
      const max = value[1];
      let query = `?min=${min}&max=${max}`;

      if (categories) query += `&categoryId=${categories}`;
      if (colors) query += `&colors=${colors}`;

      const url = `${URL_CONSTANTS.getAllProducts}${query}`;
      const response = await Get(url);

      const individualProducts = transformProducts(response.response.products);
      setAllProducts(individualProducts);
      setIsLoader(false);
    } catch (error) {
      console.error("Error fetching products:", error.message);
      setIsLoader(false);
    }
  };

  // Fetch product stats on initial load
  useEffect(() => {
    GetProductStates();
  }, []);

  // Trigger API only after all filters are set
  useEffect(() => {
    if (isInitialLoad) {
      // Only skip if `categoryId` is processed
      setIsInitialLoad(false);
      return;
    }

    const timer = setTimeout(() => {
      GetAllProduct();
    }, 300); // Add debounce for smoother UX

    return () => clearTimeout(timer);
  }, [checkedCategories, selectedColors, value]);

  // Initialize `checkedCategories` if `categoryId` exists in the URL
  useEffect(() => {
    window.scrollTo(0, 0);

    if (categoryId && !checkedCategories.includes(categoryId)) {
      // setCheckedCategories((prev) => [...prev, categoryId]);
      setCheckedCategories((prev) => [categoryId]);
    }
  }, [categoryId]);

  return (
    <>
      {isLoader && <Loader />}
      <Box className="mainWrapper">
        {/* <Header /> */}
        <Box className="">
          <Grid container padding={0} columnSpacing={2} rowSpacing={2}>
            <Grid xs={12} sm={12} md={8} lg={9} className="lookSection">
              <Box className="look">
                <>
                  <button
                    onClick={toggleDrawer}
                    className="filter_filter"
                    aria-label="Fasailter"
                  >
                    <FilterListIcon />
                    <Typography class sx={{ ml: 1 }}>
                      Filter
                    </Typography>
                  </button>

                  <Drawer
                    anchor="left"
                    open={openFilter}
                    onClose={toggleDrawer}
                    className="filter_drawer"
                  >
                    <div className="full_Div">
                      <div className="head">
                        <Typography variant="h6">FILTER</Typography>
                        <IconButton onClick={toggleDrawer}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                      <Divider sx={{ my: 2 }} />

                      <Typography className="subtitle1">Price</Typography>
                      <Slider
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        min={0}
                        max={+productStats?.maxPrice}
                        sx={{ color: "#8E282B" }}
                      />
                      <Typography
                        className="subtitle1"
                        style={{ fontSize: 14 }}
                        align="center"
                      >
                        Price: ₹{0} - ₹{+productStats?.maxPrice}
                      </Typography>
                      <Divider sx={{ my: 2 }} />

                      <Typography className="subtitle1">
                        Select Category
                      </Typography>
                      <Box className="panel_content">
                        <List className="categories">
                          {productStats?.categories?.map((data, i) => (
                            <ListItem key={i}>
                              <FormControlLabel
                                key={data._id}
                                control={
                                  <Checkbox
                                    checked={checkedCategories.includes(
                                      data._id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(data._id)
                                    }
                                    name={data.title}
                                    sx={{
                                      color: "#8E282B",
                                      "&.Mui-checked": {
                                        color: "#8E282B",
                                      },
                                    }}
                                  />
                                }
                                label={data.title}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                      <Divider sx={{ my: 2 }} />
                      <Typography className="subtitle1">
                        Select Color
                      </Typography>
                      <Box className="panel_content">
                        <div className="colors pt-6 pb-2 px-1">
                          {productStats?.colors?.map((color) => (
                            <div
                              key={color}
                              className={`hex ${
                                selectedColors.includes(color) ? "selected" : ""
                              }`}
                              style={{ background: color }}
                              onClick={() => handleColorClick(color)}
                            ></div>
                          ))}
                        </div>
                      </Box>
                    </div>
                  </Drawer>
                </>
                <Grid
                  container
                  padding={0}
                  columnSpacing={2}
                  rowSpacing={2}
                  className="look_container"
                >
                  {allProducts?.length > 0 &&
                    allProducts?.map((product) => {
                      return <ProductCard product={product} />;
                    })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ProductsList
          apiUrl={URL_CONSTANTS.getNewArrival}
          text1={"NEW"}
          text2={"ARRIVAL"}
          productToShow={8}
          buttonRedirectionUrl={userRoutes.newArrivals}
        />
        <ProductsList
          apiUrl={URL_CONSTANTS.getBestSeller}
          text1={"BEST"}
          text2={"SELLER"}
          productToShow={8}
          buttonRedirectionUrl={userRoutes.bestSeller}
        />
        <Footer />
      </Box>
    </>
  );
};

export default ProductsData;
