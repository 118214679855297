// src/components/RichTextEditor.js
import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const RichTextEditor = ({ content, onChange }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={content}
      onReady={(editor) => {
        // You can store the "editor" and use it if you want
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data);
      }}
      onBlur={(event, editor) => {
        console.log('Blur.', editor);
      }}
      onFocus={(event, editor) => {
        console.log('Focus.', editor);
      }}
    />
  );
};

export default RichTextEditor;
