import { Box, Modal } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { URL_CONSTANTS } from "../../Api/ApiUrl";
import "../../commonSCSS/modals.scss";
import { useNavigate } from "react-router-dom";
import { Get } from "../../Api/api";
import { userRoutes } from "../../Routes/UserRoutes";
import Grid from "@mui/material/Unstable_Grid2";

const SearchBar = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const debounceTimeout = useRef(null);
  const [categories, setCategories] = useState([]);
  const [featuredCollection, setFeaturedCollection] = useState([]);
  const [searchProducts, setsearchProducts] = useState([]);

  //Get all category list
  const fetchCategories = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getAllCategories);
      if (response.response?.length > 0) {
        setCategories(response.response);
      }
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  //Get getFeaturedCollection
  const getFeaturedCollection = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getfeatured);
      setFeaturedCollection(response.response);
    } catch (error) {
      console.error("Error fetching getNewArrival:", error.message);
    }
  };
  // Define the search products function
  const getSearchProducts = async (value) => {
    try {
      if (value) {
        const response = await Get(`${URL_CONSTANTS.searchProducts}${value}`);
        setsearchProducts(response.response);
      } else {
        setsearchProducts([]);
      }
    } catch (error) {
      console.error("Error fetching search products:", error.message);
    }
  };

  // Handle the input change with debounce
  const handleChange = (event) => {
    const value = event.target.value;
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      getSearchProducts(value); // Use the value directly here
    }, 500);
  };
  useEffect(() => {
    if (open) {
      fetchCategories();
      getFeaturedCollection();
    }
  }, [open]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setFeaturedCollection([]);
          setsearchProducts([]);
        }}
        className="modalWrapper"
      >
        <Box className="modalInner searchModal">
          <div className="search_drop_content">
            <div className="searrch mb-6">
              <img loading="eager" src="/images/search.svg" className="icon" />
              <input
                type="text"
                className="pro_search"
                placeholder="Search"
                onChange={handleChange}
              />
            </div>
            <p className="trending mb-6">Trending Searches</p>
            <div className="tSearch mb-6">
              {categories?.slice(-3)?.map((categoryData) => (
                <p
                  onClick={() => {
                    setOpen(false);
                    navigate(
                      `${userRoutes.products}?categoryId=${categoryData._id}`
                    );
                  }}
                >
                  {categoryData.title}{" "}
                  <img loading="eager" src="/images/tsearch.svg" alt="s" />
                </p>
              ))}
            </div>
            <div className="div_height">
              {searchProducts.length < 1 &&
                featuredCollection.slice(-2).map((products) => (
                  <div
                    className="products df"
                    onClick={() => {
                      setOpen(false);
                      navigate(
                        `${userRoutes.productDetail}?productId=${products._id}`
                      );
                    }}
                  >
                    <img
                      loading="eager"
                      src={products.variants[0].images[0]}
                      alt="pro"
                    />
                    <div className="prodiv">
                      <p>{products.title}</p>
                      <p>
                        <span style={{ textDecoration: "line-through" }}>
                          ₹ {products.variants[0].sizes[0].mrp}
                        </span>{" "}
                        {"  "}₹ {products.variants[0].sizes[0].price}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
            <div className="div_height">
              {searchProducts.length > 0 &&
                searchProducts?.map((products) => (
                  <div
                    className="products df"
                    onClick={() => {
                      setOpen(false);
                      navigate(
                        `${userRoutes.productDetail}?productId=${products._id}`
                      );
                    }}
                  >
                    <img
                      loading="eager"
                      src={products._source.variants[0].images[0]}
                      alt="pro"
                    />
                    <div className="prodiv">
                      <p>{products._source.title}</p>
                      <p>
                        <span style={{ textDecoration: "line-through" }}>
                          ₹ {products._source.variants[0].sizes[0].mrp}
                        </span>{" "}
                        ₹ {products._source.variants[0].sizes[0].price}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default SearchBar;
