import { format } from "date-fns";

export const formatCurrency = (num) => {
  // Convert to number in case the input is a string
  num = Number(num);

  // Use the Intl.NumberFormat API to format the number
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  return formatter.format(num);
};
export const transformProducts = (products) => {
  return products.flatMap((product) =>
    product.variants.map((variant) => ({
      ...product, // Spread all properties of the main product
      variants: [variant], // Replace variants with a single variant in an array
    }))
  );
};

export const calculateDiscountPercentage = (mrp, price) => {
  if (mrp <= 0) {
    return 0; // Prevent division by zero or negative MRP values
  }

  const discount = ((mrp - price) / mrp) * 100;
  return discount.toFixed(0); // Rounded to two decimal places
};
export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
export const validatePhone = (phone) => {
  const phoneRegex = /^[6-9][0-9]{9}$/; // Starts with 6-9, followed by 9 more digits
  return phoneRegex.test(phone);
};

export const capitalizeFirstLetter = (str) => {
  if (typeof str !== "string" || str.length === 0) {
    return "";
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatDateDDMMYYYY = (dateString)=> {
  return format(new Date(dateString), "dd-MM-yyyy hh:mm a");
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { weekday: "short", day: "numeric", month: "long" };
  return date
    .toLocaleDateString("en-US", options)
    .replace(/,/g, "")
    .replace(/,/, ",");
}
export function formatOrderStatus(status) {
  return status
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function truncateDescription(description, limit) {
  if (description.length > limit) {
    return description.slice(0, limit) + "...";
  }
  return description;
}


export function canReturnOrder(deliveryTime) {
  // Parse the delivery time
  const deliveryDate = new Date(deliveryTime);

  // Add 10 days to the delivery date (includes both date and time)
  const returnDeadline = new Date(deliveryDate);
  returnDeadline.setDate(returnDeadline.getDate() + 10); // Add 10 days

  // Get the current date (includes current time)
  const currentDate = new Date();

  // Check if the current date and time is before or on the return deadline
  if (currentDate <= returnDeadline) {
    return true; // The order can still be returned
  } else {
    return false; // The return window has passed
  }
}