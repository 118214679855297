import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/Header";
import AboutUs from "../pages/AboutUs";
import BestSeller from "../pages/BestSeller";
import CartData from "../pages/CartData";
import CollectionProduct from "../pages/CollectionProduct";
import ContactUs from "../pages/ContactUs";
import DashboardData from "../pages/DashboardData";
import EditProfile from "../pages/EditProfile";
import Faq from "../pages/Faq";
import NotFound from "../pages/NotFound";
import Orders from "../pages/Orders";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ProductsData from "../pages/ProductData";
import ReturnExchange from "../pages/ReturnExchange";
import ReturnRequest from "../pages/ReturnRequest";
import Shipping from "../pages/Shipping";
import SignUp from "../pages/SignUp";
import SizeChart from "../pages/SizeChart";
import Terms from "../pages/Terms";
import BlogDetail from "./../pages/BlogDetail";
import Blogs from "./../pages/Blogs";
import Checkout from "./../pages/Checkout";
import FeaturedProducts from "./../pages/FeaturedProducts";
import ForgotPassword from "./../pages/ForgotPassword";
import Login from "./../pages/Login";
import NewArrivals from "./../pages/newArrivals";
import OrderDetail from "./../pages/OrderDetail";
import ProductDetail from "./../pages/ProductDetail";
import Wishlist from "./../pages/Wishlist";
import { userRoutes } from "./UserRoutes";

const UserRoutesData = () => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos > scrollPosition) {
        setIsHeaderVisible(false);
      } else {
        setIsHeaderVisible(true);
      }
      setScrollPosition(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  return (
    <>
      <div  className={`header_trans ${isHeaderVisible ? "visible" : ""}`}>
        <Header />
      </div>
      <div className={`root_trans`}>
        <Routes>
          <Route path={userRoutes.dashboard} element={<DashboardData />} />
          <Route path={userRoutes.newArrivals} element={<NewArrivals />} />
          <Route
            path={userRoutes.collectionProducts}
            element={<CollectionProduct />}
          />
          <Route path={userRoutes.bestSeller} element={<BestSeller />} />
          <Route
            path={userRoutes.featuredProduts}
            element={<FeaturedProducts />}
          />
          <Route path={userRoutes.wishlist} element={<Wishlist />} />
          <Route path={userRoutes.cart} element={<CartData />} />
          <Route path={userRoutes.products} element={<ProductsData />} />
          <Route path={userRoutes.productDetail} element={<ProductDetail />} />
          <Route path={userRoutes.blogs} element={<Blogs />} />
          <Route path={userRoutes.blogDetail} element={<BlogDetail />} />
          <Route path={userRoutes.checkout} element={<Checkout />} />
          <Route path={userRoutes.orders} element={<Orders />} />
          <Route path={userRoutes.orderDetail} element={<OrderDetail />} />
          <Route path={userRoutes.login} element={<Login />} />
          <Route path={userRoutes.forgotPassword} element={<ForgotPassword />} />
          <Route path={userRoutes.signUp} element={<SignUp />} />
          <Route path={userRoutes.editProfile} element={<EditProfile />} />
          <Route path={userRoutes.aboutUs} element={<AboutUs />} />
          <Route path={userRoutes.contactUs} element={<ContactUs />} />
          <Route path={userRoutes.privacy} element={<PrivacyPolicy />} />
          <Route path={userRoutes.terms} element={<Terms />} />
          <Route path={userRoutes.returnExchange} element={<ReturnExchange />} />
          <Route path={userRoutes.returnRequest} element={<ReturnRequest />} />
          <Route path={userRoutes.shipping} element={<Shipping />} />
          <Route path={userRoutes.faq} element={<Faq />} />
          <Route path={userRoutes.sizeChart} element={<SizeChart />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
};

export default UserRoutesData;
