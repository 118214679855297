import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Faq = () => {
    return (
        <>
            <Box className="mainWrapper">
                
                <Box className="our_blogs privacy">
                    <Box>
                        <h1>Frequently Asked Questions</h1>
                        <h2>
                            Home/<span> Frequently Asked Questions</span>
                        </h2>
                    </Box>
                    <Box className="privacySection">
                        <Grid container padding={0}>
                            <Grid xs={12} className="mb-6">
                                <h3 className="para mb-3">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                </h3>
                                <h3 className="para mb-3">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                </h3>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Footer />
            </Box>
        </>
    );
};

export default Faq;