import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import "../Admin/admin.scss";
import { Delete } from "../Api/api";
import CustomTable from "../Supporting files/CustomTable/CustomTable";
import { ADMINURL_CONSTANTS } from "./AdminApiUrl/AdminApiUrl";
import EditProductPopup from "./EditProducts";

const AllProducts = () => {
  const [productsOptions, setProductsOptions] = useState([]);
  const [productData, setproductData] = useState({});
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [tableLimit, setTableLimit] = useState();

  const activeOrInactiveProduct = async (id, message) => {
    try {
      const response = await Delete(
        `${ADMINURL_CONSTANTS.activeOrInactiveProduct}${id}`
      );
      fetchProducts();
      toast.success(message);
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  // Fetch products from server
  const fetchProducts = useCallback(async (page = 0, pageSize = 25) => {
    try {
      const response = await axios.get(
        `${ADMINURL_CONSTANTS.GetAllProduct}?page=${page + 1}&pageSize=${pageSize}`
      );
      const products = response.data.response.products.map((product) => ({
        ...product,
        id: product._id,
      }));
      setTotalCount(response.data.response.count);
      setProductsOptions(products);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  }, []);

  // Fetch initial data
  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const [products, setProducts] = useState([
    {
      field: "id",
      headerName: "ID",
      minWidth: 200,
      check_status: true,
    },
    {
      field: "title",
      headerName: "Name",
      minWidth: 300,
      check_status: true,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 300,
      check_status: true,
      renderCell: (params) => {
        const truncatedDescription =
          params.value.length > 50
            ? `${params.value.substring(0, 50)}...`
            : params.value;
        return <span title={params.value}>{truncatedDescription}</span>;
      },
    },

    {
      field: "brand",
      headerName: "Brand",
      minWidth: 300,
      check_status: true,
    },
    {
      field: "images",
      headerName: "Images",
      minWidth: 300,
      check_status: true,
      renderCell: (params) => (
        <img loading="eager" 
          src={params?.row?.variants[0]?.images[0] || ""}
          alt="Product"
          style={{ width: 100, height: 100 }}
        />
      ),
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      minWidth: 500,
      check_status: true,
      renderCell: (params) => {
        const { row } = params;

        // Inline style for color circle
        const colorCircleStyle = {
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          display: "inline-block",
          marginRight: "8px",
        };

        // Generate quantity information with color circles
        const quantityInfo = Array.isArray(row?.variants)&& row?.variants?.map((variant) => {
            const sizeInfo = variant.sizes
              .map((size) => `${size.size} - ${size.quantity}`)
              .join(", ");

            return (
              <div
                key={variant.color}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "4px",
                }}
              >
                <div
                  style={{
                    ...colorCircleStyle,
                    backgroundColor: variant.color,
                  }}
                ></div>
                <span>{sizeInfo}</span>
              </div>
            );
          })
          .reduce((prev, curr) => [prev, curr]);

        return  (
  <span
    style={{
      display: "block", // To allow proper scrolling
      maxHeight: "50px", // Set a max height for the scrollable area
      overflowY: "auto", // Enable vertical scrolling
    }}
  >
    {quantityInfo}
  </span>
);
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 300,
      check_status: true,
      renderCell: (params) => {
        const { row } = params;

        return (
          <>
            <Button
              onClick={() => {
                setproductData(params.row);
                setPopupOpen(true);
              }}
              variant="contained"
              style={{
                color: "#fff",
                backgroundColor: "#7ac57d",
                padding: "6px 15px",
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
              startIcon={<CheckCircleIcon />}
            >
              Edit
            </Button>
            {!row.isActive ? (
              <Button
                onClick={() => {
                  activeOrInactiveProduct(row.id, "Product is Actived");
                }}
                variant="contained"
                style={{
                  color: "#fff",
                  backgroundColor: "#7ac57d",
                  padding: "6px 15px",
                  display: "flex",
                  alignItems: "center",
                }}
                startIcon={<CheckCircleIcon />}
              >
                Make it Active
              </Button>
            ) : (
              <Button
                onClick={() => {
                  activeOrInactiveProduct(row.id, "Product is InActived");
                }}
                variant="contained"
                style={{
                  color: "#fff",
                  backgroundColor: "#fb5044e3",
                  padding: "6px 12px",
                  display: "flex",
                  alignItems: "center",
                }}
                startIcon={<CancelIcon />}
              >
                Make it Inactive
              </Button>
            )}
            ;
          </>
        );
      },
    },
  ]);

  const updateMasterState = () => { };

  return (
    <>
      {isPopupOpen && (
        <EditProductPopup
          open={isPopupOpen}
          handleClose={() => setPopupOpen(false)}
          productData={productData}
          onSave={() => {}}
        />
      )}

      <Container className="adminWrapper">
        <Box sx={{ mt: 3 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            All Products
          </Typography>
          <CustomTable
            header_data={products}
            data={productsOptions}
            value_update={updateMasterState}
            attrName={"UGTable"}
            BE_filter_code={20}
            totalRecords={totalCount}
            refetch_data={fetchProducts}
            setTableLimit={setTableLimit}
            setTableSkip={setTableSkip}
          />
        </Box>
      </Container>
    </>
  );
};

export default AllProducts;
