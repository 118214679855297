import * as React from "react";
import { TextField } from "@mui/material";

const TextArea = ({
  title,
  value,
  value_update,
  attrName,
  warn_status,
  class_name,
  error_messg,
  placeholder,
  rows,
  helperText,
}) => {
  return (
    <div className={class_name}>
      <textarea
        value={value}
        placeholder={placeholder}
        rows={rows || 2}
        maxRows={5}
        onChange={(e) => {
          value_update(attrName, e.target.value.replace(/ {2,}/g, " "));
        }}
      />
      {warn_status == true ? <span className="error">{helperText}</span> : null}
    </div>
  );
};

export default TextArea;
