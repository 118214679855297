import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";

const AboutUs = () => {
  return (
    <>
      <Box className="mainWrapper">
        {/* <Header /> */}
        <Box className="our_blogs about_page">
          <Box>
            <h1>About Us</h1>
            <h2>
              Home/<span> About Us</span>
            </h2>
          </Box>
          <Box className="aboutSection">
            <Box className="headingg mb-3">
              <h2>
                Welcome To Ishnoor Store! Let Us Take You On A Journey Through
                The Ages.
              </h2>
            </Box>
            <Box>
              <Grid container padding={0}>
                <Grid xs={12}>
                  <h3 className="para mb-5">
                    Founded in 2023, Ishnoor is an Indian brand dedicated to
                    authentic and sustainable Chikankari clothing. At Ishnoor,
                    our mission is to make handmade products accessible and
                    affordable for all. In a landscape filled with fast fashion,
                    we shine a light on the often-overlooked stories of artisans
                    whose dedication, creativity, and passion are woven into
                    each piece.
                  </h3>
                </Grid>
                <Grid xs={12}>
                  <h3 className="para mb-5">
                    As a homegrown label, we celebrate Chikankari as a true art
                    form. Every step of our manufacturing process, from sourcing
                    materials to hand embroidery, is carried out locally by
                    skilled women artisans. Our collection offers a vast array
                    of options, from everyday cotton suits to elegant party wear
                    designer outfits, all at wallet-friendly prices. If you’re
                    craving something traditional, Ishnoor also features kurta
                    sets and more.
                  </h3>
                </Grid>
                <Grid xs={12}>
                  <h3 className="para mb-5">
                    We are committed to making India's cherished Chikankari
                    craft a staple in every home and sharing the beauty of
                    Lucknow with the world.
                  </h3>
                </Grid>
                <Grid xs={12}>
                  <img
                    loading="eager"
                    className="abtImg w-100"
                    src="https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/DSC08362.JPG-1733158243675.JPG"
                    alt="about"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default AboutUs;
