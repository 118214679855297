import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useCallback, useEffect, useRef, useState } from "react";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Get } from "../Api/api";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Loader from "../components/Loader/Loader";
import ProductCard from "../components/ProductCard";

const NewArrivals = () => {
  const [categories, setCategories] = useState([]);
  const [activeCat, setActiveCat] = useState(null);
  const [newArrivals, setNewArrivals] = useState([]);
  const [loader, setloader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const observer = useRef();

 
  //Get all category list
  const fetchCategories = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getAllCategories);
      if (response.response?.length > 0) {
        setCategories(response.response);
      }
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  //Get new arrival
  const getNewArrival = async (id, pageNum = 1,dummy,localpage) => {
    try {
      const response = await Get(
        `${URL_CONSTANTS.getNewArrival}?categoryId=${id ?? ""}&page=${
          localpage?localpage: page
        }&pageSize=${pageSize}`
      );
      setloader(false);

      if (response.response.length > 0) {
        setNewArrivals((prevArrivals) => [
          ...prevArrivals,
          ...response.response,
        ]);
        const totalLoaded = (pageNum - 1) * pageSize + response.response.length;
        setHasMore(totalLoaded < response.count);
        setLoadingMore(false);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching getNewArrival:", error.message);
    }
  };
  const lastProductRef = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLoadingMore(true);
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, hasMore]
  );

  useEffect(() => {
    if (page > 1) {
      getNewArrival(activeCat ?? "", page, pageSize); // Fetch the next page
    }
  }, [page]);
  useEffect(() => {
    fetchCategories();
    getNewArrival();
  }, []);

  return (
    <>
      {loader && <Loader />}
      <Box className="mainWrapper">
        {/* <Header /> */}
        <Box className="shopSection">
          <Box className="shop_box">
            <h2 className="theme_heading">
              New <span>Arrivals</span>
            </h2>
            <Box className="arrivall">
              <Grid container spacing={3} className="category_container">
                <Grid xs={12}>
                  <div className="cat_scroller">
                    {categories?.length > 0 &&
                      categories?.map((categoryData, i) => (
                        <div
                          className="category pb-0"
                          key={categoryData?._id}
                          onClick={() => {
                            setPage(1);
                            setNewArrivals([]);
                            getNewArrival(
                              activeCat === categoryData._id ? "" : categoryData._id,"","",1
                            );
                          }}
                        >
                          <img
                            loading="eager"
                            onError={(e) => {
                              e.target.onError = null;
                              e.target.src = "images/dummy_category.jpg";
                            }}
                            className={`${
                              activeCat === categoryData._id ? "active" : ""
                            }`}
                            onClick={() => {
                              setActiveCat(
                                activeCat === categoryData._id
                                  ? ""
                                  : categoryData._id
                              );
                            }}
                            src={
                              categoryData.image || "images/dummy_category.jpg"
                            }
                            alt={categoryData.title}
                          />
                          <p>{categoryData.title}</p>
                        </div>
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box className={`lookSection`}>
              <Box className="look">
                <Box>
                  <Grid
                    container
                    className="look_container"
                    columnSpacing={2}
                    rowSpacing={4}
                  >
                    {newArrivals?.length > 0 &&
                      newArrivals?.map((item, index) => {
                        return <ProductCard product={item} />;
                      })}
                    {/* Ref for the last product to trigger more load */}
                    <div ref={lastProductRef} style={{ height: "20px" }} />
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};
export default NewArrivals;
