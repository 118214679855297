import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import SDTextField from "../Supporting files/SDTextField/SDTextField";
import PasswordSuccess from "../components/Modals/PasswordSuccess";
import { userRoutes } from "../Routes/UserRoutes";
import { useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Post } from "../Api/api";
import toast from "react-hot-toast";
import axios from "axios";
import { validateEmail } from "../Supporting files/HelpingFunction";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setotp] = useState("");
  const [token, settoken] = useState("");
  const [password, setpassword] = useState("");
  const [Confirmpassword, setConfirmpassword] = useState("");
  const [pwSuccess, setPwSuccess] = useState(false);

  const updateMaster = (attr, value) => {
    attr(value);
  };
  const submit = () => {
    if (step === 1) {
      return verifyEmail();
    }
    if (step === 3) {
      return changePassword();
    }
    setStep(step + 1);
  };

  const verifyEmail = async () => {
    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email address.");
    }
    const payload = {
      email: email,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.forgetPassword}`,
        JSON.stringify(payload)
      );
      toast.success(response.message);
      setEmail("");
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //   setLoading(false);
    }
  };
const changePassword = async () => {
  const payload = {
    confirmPassword: Confirmpassword,
    password: password,
  };

  try {
    // Define the headers with 'auth' for token
    const headers = {
      "Content-Type": "application/json", // Set the content type
      auth: token, // Send the token in 'auth' header as in Postman
    };

    // Make the POST request using Axios
    const response = await axios.post(
      `${URL_CONSTANTS.resetPassword}`,
      payload, // Axios automatically stringifies the payload
      { headers }
    );

    // Handle success
    setPwSuccess(true);
    navigate(userRoutes.login);
    toast.success(response.data.message);
  } catch (error) {
    // Handle error
    console.error("Error ", error);
    toast.error("Password reset failed.");
  }
};

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    let tokenn = params.get("userToken");
    settoken(tokenn);
    if (tokenn) {
      setStep(3);
    } else {
      setStep(1);
    }
  }, []);

  return (
    <>
      <PasswordSuccess pwSuccess={pwSuccess} setPwSuccess={setPwSuccess} />
      <Box className="">
        {/* <Box className="mainWrapper"> */}
        <div className="login_screen">
          <div className="login">
            <Box>
              <Grid container padding={0} textAlign={"center"}>
                <Grid xs={12} className="mb-7">
                  <img
                    loading="eager"
                    onClick={() => navigate(userRoutes.dashboard)}
                    src="/images/logo.png"
                    alt="logo"
                    style={{ cursor: "pointer" }}
                  />
                </Grid>
                <Grid xs={12} className="mb-7">
                  <h1 className="welcome">
                    {step === 1
                      ? "Forgot Password"
                      : step === 2
                      ? "Enter Code"
                      : "Create New Password"}
                  </h1>
                </Grid>
                {step === 1 && (
                  <Grid xs={12} className="mb-6">
                    <SDTextField
                      title="Email Address"
                      value={email}
                      attrName={setEmail}
                      value_update={updateMaster}
                      cls="w-100"
                    />
                  </Grid>
                )}
                {step === 2 && (
                  <Grid xs={12} className="mb-6">
                    <SDTextField
                      title="Enter Code"
                      value={otp}
                      attrName={setotp}
                      value_update={updateMaster}
                      cls="w-100"
                    />
                  </Grid>
                )}
                {step === 3 && (
                  <>
                    <Grid xs={12} className="mb-6">
                      <SDTextField
                        title="New Password"
                        value={password}
                        attrName={setpassword}
                        value_update={updateMaster}
                        cls="w-100"
                        type="password"
                      />
                    </Grid>
                    <Grid xs={12} className="mb-6">
                      <SDTextField
                        title="Confirm Password"
                        value={Confirmpassword}
                        attrName={setConfirmpassword}
                        value_update={updateMaster}
                        cls="w-100"
                        type="password"
                      />
                    </Grid>
                  </>
                )}

                <Grid xs={12} className="ctaBtn mb-6">
                  <Button className="submitBtn w-100" onClick={submit}>
                    {step === 1 ? "Continue" : "Verify"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </Box>
    </>
  );
};

export default ForgotPassword;
