import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../commonSCSS/BannerSlider.scss";
import { userRoutes } from "../Routes/UserRoutes";

const BannerSlider = () => {
  const navigate = useNavigate();
  const [activeSlide, setActiveSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    arrows: false,
    afterChange: (current) => setActiveSlide(current),
  };

  return (
    <div className="banner-slider">
      <Slider {...settings}>
        {/* First Slide */}
        <div
          className={`slider-item ${activeSlide === 0 ? "mobile-slide-1" : ""}`}
        >
          <img
            src={
              "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/Hero.png-1730360316253.png"
            }
            alt="Festive Delights"
            className="slider-image"
          />
          <img
            src={
              "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/Herores.png-1729270587698.png"
            }
            alt="Festive Delights Mobile"
            className="slider-image-mobile"
          />
          <div
            className={`slider-content ${isMobile ? "center-text" : "center-text"
              } black-text`}
          >
            <h1>End of Season Sale</h1>
            <p>Enjoy Up to 60% Off !</p>
            <button
              className="shop-now-btn"
              onClick={() => navigate(userRoutes.products)}
            >
              Shop Now
            </button>
          </div>
        </div>

        {/* Second Slide */}
        <div
          className={`slider-item ${activeSlide === 1 ? "mobile-slide-2" : ""}`}
        >
          <img
            src={
              "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/Hero2.png-1729270383612.png"
            }
            alt="Bestseller"
            className="slider-image"
          />
          <img
            src={
              "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/Hero2res.png-1729270587686.png"
            }
            alt="Bestseller Mobile"
            className="slider-image-mobile"
          />
          <div
            className={`slider-content ${isMobile ? "center-text" : "left-text"
              } black-text`}
          >
            <h1>Bestseller</h1>
            <p>Explore our bestselling products</p>
            <button
              className="shop-now-btn"
              onClick={() => navigate(userRoutes.bestSeller)}
            >
              Shop Now
            </button>
          </div>
        </div>

        {/* Third Slide */}
        <div
          className={`slider-item ${activeSlide === 2 ? "mobile-slide-3" : ""}`}
        >
          <img
            src={
              "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/Hero.png-1729703692197.png"
            }
            alt="New Drop Falak"
            className="slider-image"
          />
          <img
            src={
              "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/Android%20Medium%20-%205.png-1729703714784.png"
            }
            alt="New Drop Falak Mobile"
            className="slider-image-mobile"
          />
          <div
            className={`slider-content ${isMobile ? "center-text" : "left-text"
              } black-text`}
          >
            <h1>New Drop Falak</h1>
            <button
              className="shop-now-btn"
              onClick={() => navigate(userRoutes.newArrivals)}
            >
              Shop Now
            </button>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default BannerSlider;
