import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import AdminLogin from "./Admin/AdminLogin";
import AdminLayout from "./Routes/AdminLayout";
import { adminRoutes } from "./Routes/AdminRoutes";
import AdminRoutesData from "./Routes/AdminRoutesData";
import UserRoutesData from "./Routes/UserRoutesData";

// Import WhatsApp Icon
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box } from "@mui/material";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <BrowserRouter>
      <Toaster
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <ScrollToTop />
      <Routes>
        <Route path={`admin/${adminRoutes.login}`} element={<AdminLogin />} />
        <Route path="admin/*" element={<AdminLayout />}>
          {Cookies.get("ishnooradminToken") ? (
            <Route path="*" element={<AdminRoutesData />} />
          ) : (
            <Route path="*" element={<AdminLogin />} />
          )}
        </Route>
        <Route path="/*" element={<UserRoutesData />} />
      </Routes>

      {/* WhatsApp Icon at the bottom right */}
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 9999,
          backgroundColor: "green",
          borderRadius: "50%",
          width: 56, // Explicit width for the circle
          height: 56, // Explicit height for the circle
          display: "flex",
          justifyContent: "center", // Centers the icon horizontally
          alignItems: "center", // Centers the icon vertically
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <a
          href="https://wa.me/+918175918027" // Replace with your WhatsApp number
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white", fontSize: "30px" }}
        >
          <WhatsAppIcon />
        </a>
      </Box>
    </BrowserRouter>
  );
}

export default App;
