import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Zoom, Navigation, Pagination } from "swiper/modules";

export default function ImageSwiperWithZoom({ imageUrls, onClose }) {
  const [swiperInstance, setSwiperInstance] = useState(null); // Track Swiper instance
  const [isZoomed, setIsZoomed] = useState(false); // Track zoom state

  // Handle single click for zoom toggle
  const handleSingleClick = () => {
    if (!swiperInstance) return; // Ensure Swiper instance exists
    const zoom = swiperInstance.zoom;

    if (isZoomed) {
      zoom.out(); // Zoom out
      setIsZoomed(false);
    } else {
      zoom.in(); // Zoom in
      setIsZoomed(true);
    }
  };

  return (
    <div className="swiper-container">
      <button onClick={onClose} className="close-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="white"
        >
          <path d="M12 10.586L16.95 5.636l1.414 1.414L13.414 12l5.95 5.95-1.414 1.414L12 13.414l-5.95 5.95-1.414-1.414L10.586 12 4.636 6.05 6.05 4.636z" />
        </svg>
      </button>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        onSwiper={(swiper) => setSwiperInstance(swiper)} // Save Swiper instance
        zoom={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[Zoom, Navigation, Pagination]}
        className="mySwiper"
      >
        {imageUrls.map((src, index) => (
          <SwiperSlide key={index}>
            <div className="swiper-zoom-container" onClick={handleSingleClick}>
              <img
                src={src}
                alt={`Image ${index + 1}`}
                className={`swiper-image ${isZoomed ? "zoom-out" : "zoom-in"}`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <style jsx>{`
        .swiper-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .swiper-zoom-container {
          width: 50%; /* Default width */
          height: 80%; /* Default height */
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          cursor: ${isZoomed ? "zoom-out" : "zoom-in"};
        }

        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .swiper-image {
          width: 80%;
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }

        .close-button {
          position: absolute;
          top: 20px;
          right: 20px;
          z-index: 10;
          background: transparent;
          border: none;
          cursor: pointer;
        }

        .close-button:hover {
          opacity: 0.7;
        }

        /* Mobile view - Adjust the zoom container size */
        @media (max-width: 768px) {
          .swiper-zoom-container {
            width: 100%; /* Increase width on smaller screens */
            height: 100%; /* Keep it large enough */
          }
          .swiper-image {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
          .swiper-button-next,
          .swiper-button-prev {
            width: 30px; /* Smaller width */
            height: 30px; /* Smaller height */
            font-size: 20px; /* Smaller font size for arrows */
          }
        }

        /* Small mobile view - Further adjust for very small screens */
        @media (max-width: 480px) {
          .swiper-zoom-container {
            width: 100%; /* Almost full screen on very small devices */
            height: 100%;
          }
          .swiper-image {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
          .swiper-button-next,
          .swiper-button-prev {
            width: 25px; /* Smaller width */
            height: 25px; /* Smaller height */
            font-size: 18px; /* Smaller font size for arrows */
          }
        }
      `}</style>
    </div>
  );
}
