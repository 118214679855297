import React from "react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { userRoutes } from "../../Routes/UserRoutes";

const SizeChartModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "70%", md: "50%" },
          bgcolor: "background.paper",
          border: "1px solid #ddd",
          borderRadius: 2,
          boxShadow: 24,
          p: 2,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "grey.500",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          component="img"
          src="https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/top.png-1731856019265.png"
          alt="Size Chart"
          sx={{
            width: "100%",
            maxHeight: "600px",
            objectFit: "contain",
            borderRadius: 1,
            mb: 2,
          }}
        />

        {/* Navigation Link */}
        <Link
          to={userRoutes.sizeChart}
          style={{
            display: "block",
            textAlign: "center",
            textDecoration: "none",
            color: "#2525b3cf",
            fontWeight: "bold",
            fontSize: "14px",
            marginTop: "16px",
          }}
        >
          View More Details
        </Link>
      </Box>
    </Modal>
  );
};

export default SizeChartModal;
