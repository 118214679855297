import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SDTextField from "../Supporting files/SDTextField/SDTextField";
import { userRoutes } from "../Routes/UserRoutes";
import { Get, Post } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import toast from "react-hot-toast";
import {
  setUserData,
  updateCart,
  updateWishlist,
} from "../Redux/Slice/UserSlice";
import Cookies from "js-cookie";
import Loader from "../components/Loader/Loader";
import { validateEmail } from "../Supporting files/HelpingFunction";
// import loginBg from '../../public/images/login_bg.png';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [showError, setshowError] = useState(false);

  const handleLogin = async () => {
    if (!validateEmail(email) || !password) {
      setshowError(true);
     return seterrorMessage("Please enter valid email and password");
    }
    setshowError(false);
    setLoading(true);
    const payload = {
      email: email,
      password: password,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.login}`,
        JSON.stringify(payload)
      );
      const { token } = response.response;
      dispatch(setUserData(response.response));
      setLoading(false);
      navigate(userRoutes.dashboard);
      Cookies.set("ishnoorauthToken", token, { expires: 1 });
      toast.success("Logged in successfully");
      setTimeout(() => {
        fetchWishList();
        fetchCart();
      }, 1500);
    } catch (error) {
      console.error("Error ", error.response.data.message);
      seterrorMessage(error.response.data.message);
      setshowError(true);
      setLoading(false);
    }
  };
  //Get all wishlist list
  const fetchWishList = async () => {
    try {
      const response = await Get(`${URL_CONSTANTS.getWishlist}`);
      dispatch(updateWishlist(response.response.products));
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  const fetchCart = async () => {
    try {
      const response = await Get(`${URL_CONSTANTS.getCartItems}`);
      dispatch(updateCart(response.response));
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  return (
    <>
      {loading && <Loader />}
      <Box className="">
        <div className="login_screen">
          <div className="login">
            <Box>
              <Grid container padding={0} textAlign={"center"}>
                <Grid xs={12} className="mb-7">
                  <img
                    loading="eager"
                    onClick={() => navigate(userRoutes.dashboard)}
                    src="/images/logo.png"
                    alt="logo"
                    style={{ cursor: "pointer" }}
                  />
                </Grid>
                <Grid xs={12} className="mb-2">
                  <h1 className="welcome">WELCOME</h1>
                </Grid>
                <Grid xs={12} className="mb-7">
                  <p className="welMsg">
                    Hey, Enter your details to get sign in to your account
                  </p>
                </Grid>

                {showError && (
                  <Grid xs={12} className="mb-7">
                    <p className="errormsg">{errorMessage}</p>
                  </Grid>
                )}
                <Grid xs={12} className="mb-3">
                  <SDTextField
                    title="Username/Email"
                    value={email}
                    attrName={setEmail}
                    value_update={(d, e) => setEmail(e)}
                    cls="w-100"
                    required
                  />
                </Grid>
                <Grid xs={12} className="mb-3">
                  <SDTextField
                    title="Password"
                    type="password"
                    value={password}
                    attrName={setPassword}
                    value_update={(d, e) => setPassword(e)}
                    cls="w-100"
                    required
                  />
                </Grid>
                <Grid xs={12} className="mb-6" textAlign={"end"}>
                  <h2
                    onClick={() => navigate(userRoutes.forgotPassword)}
                    className="forgot_pw"
                  >
                    Forgot Password?
                  </h2>
                </Grid>
                <Grid xs={12} className="ctaBtn mb-6">
                  <Button className="submitBtn w-100" onClick={handleLogin}>
                    Login
                  </Button>
                </Grid>
                <Grid xs={12} className="mb-0">
                  <p className="forgot">
                    Don't have an account?{" "}
                    <span onClick={() => navigate(userRoutes.signUp)}>
                      Sign Up
                    </span>
                  </p>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </Box>
    </>
  );
};

export default Login;
