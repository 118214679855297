import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "../Admin/admin.scss";
import { Get } from "../Api/api";
import CustomTable from "../Supporting files/CustomTable/CustomTable";
import { ADMINURL_CONSTANTS } from "./AdminApiUrl/AdminApiUrl";

const AllUsers = () => {
  const [totalCount, setTotalCount] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [tableLimit, setTableLimit] = useState();
  const [users, setUsers] = useState([
    {
      field: "id",
      headerName: "ID",
      minWidth: 150,
      check_status: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 300,
      check_status: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 300,
      check_status: true,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 300,
      check_status: true,
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 300,
      check_status: true,
    },
  ]);

  const [body, setBody] = useState([]);
  useEffect(() => {
    getAllUsers();
  }, []);
  const updateMasterState = () => {};
  const getAllUsers = async (page = 0, pageSize = 25) => {
    try {
      const response = await Get(
        `${ADMINURL_CONSTANTS.GetAllUsers}?page=${
          page + 1
        }&pageSize=${pageSize}`
      );
      setTotalCount(response.count);

      const allOrders = response.response.map((order) => ({
        ...order,
        id: order._id,
      }));
      setBody(allOrders);
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  return (
    <Container className="adminWrapper">
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          All Users
        </Typography>
        <CustomTable
          header_data={users}
          data={body}
          value_update={updateMasterState}
          attrName={"UGTable"}
          BE_filter_code={20}
          totalRecords={totalCount}
          refetch_data={getAllUsers}
          setTableLimit={setTableLimit}
          setTableSkip={setTableSkip}
        />
      </Box>
    </Container>
  );
};

export default AllUsers;
