export const DeliveryStatus = {
  ORDER_PLACED: "order_placed",
  PACKAGING: "packaging",
  ON_THE_ROAD: "on_the_road",
  DELIVERED: "delivered",
  CANCELLED: "order_cancelled",
};
export const ReturnStatus = {
  Requested: "requested",
  Processing: "processing",
  Rejected: "rejected",
  Returned: "returned",
};
