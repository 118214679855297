import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Get } from "../Api/api";
import { userRoutes } from "../Routes/UserRoutes";
import ProductCard from "./ProductCard";

const ProductsList = ({
  apiUrl,
  text1,
  text2,
  productToShow,
  buttonRedirectionUrl,
  bg,
}) => {
  const navigate = useNavigate();
  const [getAllProducts, setGetAllProducts] = useState([]);

  useEffect(() => {
    GetAllProduct();
  }, []);
console.log("getAllProducts", getAllProducts);
  //Get getAllProducts
  const GetAllProduct = async () => {
    try {
      const response = await Get(apiUrl);
      setGetAllProducts(response.response.products || response.response);
    } catch (error) {
      console.error("Error fetching getAllProducts:", error.message);
    }
  };

  return (
    <>
      {getAllProducts.length > 0 && (
        <Box
          className={`lookSection`}
          sx={{
            background: bg ? "#FFEFEF" : "none",
          }}
        >
          <Box className="look">
            <h2 className="theme_heading_xl">
              {text1.toUpperCase()} <span>{text2.toUpperCase()}</span>
            </h2>
            <Box>
              <Grid
                container
                className="look_container"
                columnSpacing={2}
                rowSpacing={4}
              >
                {getAllProducts
                  ?.slice(-productToShow || -10)
                  ?.map((product) => {
                    return <ProductCard product={product} />;
                  })}

                <Grid xs={12} className="ctaBtn text-center my-6 ">
                  <Button
                    onClick={() => {
                      navigate(buttonRedirectionUrl || userRoutes.products);
                    }}
                    variant="contained"
                    className="submitBtn"
                  >
                    View All
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProductsList;
