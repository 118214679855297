import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Admin/admin.scss";
import { Get, Patch } from "../Api/api";
import CustomTable from "../Supporting files/CustomTable/CustomTable";
import { DeliveryStatus } from "../Supporting files/Enum";
import {
  capitalizeFirstLetter,
  formatCurrency,
  formatDateDDMMYYYY,
  formatOrderStatus,
} from "../Supporting files/HelpingFunction";
import { adminRoutes } from "./../Routes/AdminRoutes";
import Loader from "./../components/Loader/Loader";
import { ADMINURL_CONSTANTS } from "./AdminApiUrl/AdminApiUrl";
import toast from "react-hot-toast";

const AllOrders = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [tableLimit, setTableLimit] = useState();

  const orderStatusOptions = [
    { value: DeliveryStatus.ORDER_PLACED, label: "Order Placed" },
    { value: DeliveryStatus.PACKAGING, label: "Packaging" },
    { value: DeliveryStatus.ON_THE_ROAD, label: "On the Road" },
    { value: DeliveryStatus.DELIVERED, label: "Delivered" },
    { value: DeliveryStatus.CANCELLED, label: "Cancelled" },
  ];

  const [orders, setOrders] = useState([
    {
      field: "product",
      headerName: "Products",
      flex: 3,
      minWidth: 200,
      check_status: true,
      renderCell: (ValueFormatterParams) => {
        const { row } = ValueFormatterParams;
        return (
          <p
            onClick={() => {
              navigate(`/admin/${adminRoutes.orderDetails}?id=${row.id}`);
            }}
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            {row.products[0].productDetail.title}
          </p>
        );
      },
    },
    {
      field: "updatestatus",
      headerName: "Update Order Status",
      flex: 3,
      minWidth: 200,
      check_status: true,
      renderCell: (params) => {
        let { row } = params;
        const handleStatusChange = (e) => {
          const newStatus = e.target.value;
          if (newStatus === "order_cancelled")
            return toast.error("You can't cancel order once placed");
          updateOrderstatus(row._id, newStatus);
        };

        return (
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={row.deliveryStatus || ""} // Ensure a valid default value
              onChange={(e) => handleStatusChange(e, row)}
              label="Status"
              sx={{
                width: "100%", // Ensure dropdown fits within the cell
                height: 40, // Adjust height for visibility
                fontSize: 14, // Smaller font to fit better
              }}
            >
              {orderStatusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },

    {
      field: "paymentMode",
      headerName: "Payment Mode",
      minWidth: 100,
      check_status: true,
      renderCell: (ValueFormatterParams) => {
        const { row } = ValueFormatterParams;
        return formatOrderStatus(row.paymentMode ?? "Cancelled");
      },
    },
    {
      field: "transaction",
      headerName: "Transaction Status",
      minWidth: 200,
      check_status: true,
      renderCell: (ValueFormatterParams) => {
        const { row } = ValueFormatterParams;
        return capitalizeFirstLetter(row.transaction.status);
      },
    },
{
  field: "address",
  headerName: "Full Address",
  minWidth: 500,
  flex: 1,
  renderCell: (ValueFormatterParams) => {
    const { row } = ValueFormatterParams;
    const fields = [
      row.deliveryAddress.name,
      row.deliveryAddress.address,
      row.deliveryAddress.city,
      row.deliveryAddress.district,
      row.deliveryAddress.pincode,
      row.deliveryAddress.tag,
    ];
    // Filter out undefined or empty values and join them with a comma
    const fullAddress = fields.filter(Boolean).join(", ");
    return fullAddress || "Address details not available";
  },
},


    {
      field: "transactiondate",
      headerName: "Transaction Date/Time",
      minWidth: 200,
      check_status: true,
      renderCell: (ValueFormatterParams) => {
        const { row } = ValueFormatterParams;
        return formatDateDDMMYYYY(row.transaction.date);
      },
    },
    {
      field: "userFullName",
      headerName: "User Name",
      minWidth: 200,
      check_status: true,
      renderCell: (ValueFormatterParams) => {
        const { row } = ValueFormatterParams;
        return capitalizeFirstLetter(
          row.user.firstName + " " + row.user.lastName
        );
      },
    },
    {
      field: "userEmail",
      headerName: "User Email",
      minWidth: 300,
      check_status: true,
      renderCell: (ValueFormatterParams) => {
        const { row } = ValueFormatterParams;
        return row.user.email;
      },
    },
    {
      field: "orderNumber",
      headerName: "Order Number",
      minWidth: 200,
      check_status: true,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      minWidth: 200,
      check_status: true,
      renderCell: (ValueFormatterParams) => {
        const { row } = ValueFormatterParams;
        return formatCurrency(row.totalAmount);
      },
    },
  ]);

  const [body, setBody] = useState([]);

  useEffect(() => {
    getAllOrders();
  }, []);

  const updateMasterState = () => {};

  const updateOrderstatus = async (id, status) => {
    let dto = {
      status,
    };
    try {
      const response = await Patch(
        `${ADMINURL_CONSTANTS.updateOrderstatus}${id}`,
        dto
      );
      getAllOrders();
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  const getAllOrders = async (page = 0, pageSize = 25) => {
    try {
      const response = await Get(
        `${ADMINURL_CONSTANTS.GetAllOrders}?page=${
          page + 1
        }&pageSize=${pageSize}`
      );
      const allOrders = response.response.map((order) => ({
        ...order,
        id: order._id,
      }));
      setTotalCount(response.count);
      setBody(allOrders);
      setLoading(false);
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  return (
    <Container className="adminWrapper">
      {loading && <Loader />}

      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          All Orders
        </Typography>
        <CustomTable
          header_data={orders}
          data={body}
          value_update={updateMasterState}
          attrName={"UGTable"}
          BE_filter_code={20}
          totalRecords={totalCount}
          refetch_data={getAllOrders}
          setTableLimit={setTableLimit}
          setTableSkip={setTableSkip}
        />
      </Box>
    </Container>
  );
};

export default AllOrders;
