import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SDTextField from "../Supporting files/SDTextField/SDTextField";
import { userRoutes } from "./../Routes/UserRoutes";
import {
  validateEmail,
  validatePhone,
} from "../Supporting files/HelpingFunction";
import { Post } from "../Api/api";
import toast from "react-hot-toast";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { updateReturnOrderNumber } from "../Redux/Slice/UserSlice";
import Loader from "../components/Loader/Loader";

const ReturnRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderNumber, name, orderId, productId, _id } = useSelector(
    (state) => state?.user?.returnOrderNumber
  );
  const email = useSelector((state) => state?.user.userInfo.userDetail.email);
  const [contactInfo, setContactInfo] = useState(email);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setloading] = useState(false);

  // Fetch orders based on order number
  useEffect(() => {
    if (!orderNumber) {
      navigate(userRoutes.dashboard);
    }
  }, [orderNumber]);

  const handleValueUpdate = (attrName, value) => {
    if (attrName === "contactInfo") {
      setContactInfo(value);
    }
  };
  const submitExchangeRequest = async () => {
    setloading(true);
    let payload = {
      orderId,
      orderNumber,
      orderProductId: _id,
      productId,
    };
    try {
      const response = await Post(`${URL_CONSTANTS.returnRequest}`, payload);
    setloading(false);
      toast.success("Return Request Submitted Successfully",{
        duration:4000
      });
      navigate(userRoutes.dashboard);
      dispatch(updateReturnOrderNumber({}));

    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  const handleSubmit = () => {
    // Validate that all fields are filled
    if (!validateEmail(contactInfo) && !validatePhone(contactInfo)) {
      setErrorMessage(true);
      return;
    } else {
      setErrorMessage(false);
    }
    submitExchangeRequest();
  };

  return (
    <>
      {loading && <Loader />}
      <Box className="mainWrapper">
        {/* <Header /> */}
        <Box className="our_blogs contact_page">
          <Box className="contactSection">
            <Box className="touch">
              <Grid container padding={0}>
                <Grid xs={12} className="touchHead mb-6">
                  <h2 className="h2 mb-4">Return/Exchange Request</h2>
                  <p className="ptag">
                    Enter your order number and Email or Phone to find your
                    order
                  </p>
                </Grid>
                <Grid xs={12} lg={6} className="touchimg mb-3">
                  <img
                    loading="eager"
                    className="w-100"
                    src="images/getintouch.svg"
                    alt="Get in touch"
                  />
                </Grid>
                <Grid xs={12} lg={6} className="touchForm">
                  <Box>
                    <Grid container spacing={3}>
                      <Grid xs={12}>
                        <SDTextField
                          title="Order Number"
                          value={`${orderNumber} - ${name}`}
                          attrName={"orderNumber"}
                          value_update={() => {toast.error(
                            "You can't modify your order details."
                          );}}
                          cls="w-100"
                        />
                      </Grid>
                      <Grid xs={12}>
                        <SDTextField
                          title="Email or Phone Number"
                          value={contactInfo}
                          attrName={"contactInfo"}
                          value_update={() => {toast.error("You can't modify default email address.")}}
                          cls="w-100"
                          required={true}
                          warn_status={errorMessage}
                          helperText={
                            contactInfo.length > 0
                              ? "Enter valid Email or Phone Number"
                              : "Enter Email or Phone Number"
                          }
                        />
                      </Grid>
                      <Grid xs={12}>
                        <h5 className="msgB">Return & Exchange Policy</h5>
                        <h5 className="msgB2">
                          Please remember: don't hand over products to delivery
                          until the pick-up message is received. Make a
                          video/picture during the handover. We arrange pick-ups
                          via 3rd-party courier. Ishnoor is not liable if handed
                          to the wrong person.
                        </h5>
                      </Grid>
                      <Grid xs={12} className="ctaBtn text-left">
                        <Button
                          variant="contained"
                          className="submitBtn"
                          onClick={handleSubmit}
                        >
                          Submit Request
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default ReturnRequest;
