import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Delete, Get, Post, Put } from "../Api/api";
import { userRoutes } from "../Routes/UserRoutes";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { updateCart, updateWishlist } from "../Redux/Slice/UserSlice";
import toast from "react-hot-toast";
import { sizeArray } from "../StaticData/static.enum";
import ProductCard from "../components/ProductCard";
const BestSeller = () => {
  const wishlistData = useSelector((state) => state?.user?.wishlist);
  const cartData = useSelector((state) => state?.user?.cart?.products);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [activeCat, setActiveCat] = useState(null);
  const [bestSeller, setBestSeller] = useState([]);
  const [loader, setloader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const observer = useRef();

  //Get all category list
  const fetchCategories = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getAllCategories);
      if (response.response?.length > 0) {
        setCategories(response.response);
      }
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  //Get new arrival
  // const getBestSeller = async (id, pageNum = 1, dummy, localpage) => {
  //   try {
  //     const response = await Get(
  //       `${URL_CONSTANTS.getNewArrival}?categoryId=${id ?? ""}&page=${
  //         localpage ?? page
  //       }&pageSize=${pageSize}`
  //     );
  //     setloader(false);

  //     if (response.response.products.length > 0) {
  //       setBestSeller((prevArrivals) => [
  //         ...prevArrivals,
  //         ...response.response.products,
  //       ]);
  //       const totalLoaded =
  //         (pageNum - 1) * pageSize + response.response.products.length;
  //       setHasMore(totalLoaded < response.response.count);
  //       setLoadingMore(false);
  //     } else {
  //       setHasMore(false);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching getNewArrival:", error.message);
  //   }
  // };

    const getNewArrival = async (id, pageNum = 1, dummy, localpage) => {
      try {
        const response = await Get(
          `${URL_CONSTANTS.getNewArrival}?categoryId=${id ?? ""}&page=${
            localpage ? localpage : page
          }&pageSize=${pageSize}`
        );
        setloader(false);

        if (response.response.length > 0) {
          setBestSeller((prevArrivals) => [
            ...prevArrivals,
            ...response.response,
          ]);
          const totalLoaded =
            (pageNum - 1) * pageSize + response.response.length;
          setHasMore(totalLoaded < response.count);
          setLoadingMore(false);
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error("Error fetching getNewArrival:", error.message);
      }
    };
  const lastProductRef = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLoadingMore(true);
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, hasMore]
  );

  useEffect(() => {
    if (page > 1) {
      getNewArrival(activeCat ?? "", page, pageSize); // Fetch the next page
    }
  }, [page]);
  useEffect(() => {
    fetchCategories();
    getNewArrival();
  }, []);

  return (
    <>
      {loader && <Loader />}
      <Box className="mainWrapper">
        <Box className="shopSection">
          <Box className="shop_box">
            <h2 className="theme_heading">
              Best <span>Seller</span>
            </h2>
            <Box marginBottom={"80px"}>
              <Grid container spacing={3} className="category_container">
                <Grid xs={12}>
                  <div className="cat_scroller">
                    {categories?.length > 0 &&
                      categories?.map((categoryData, i) => (
                        <div
                          className="category pb-0"
                          key={categoryData?._id}
                          onClick={() => {
                            setPage(1);
                            setBestSeller([]);
                            getNewArrival(
                              activeCat === categoryData._id
                                ? ""
                                : categoryData._id,
                              "",
                              "",
                              1
                            );
                          }}
                        >
                          <img
                            loading="eager"
                            onError={(e) => {
                              e.target.onError = null;
                              e.target.src = "images/dummy_category.jpg";
                            }}
                            className={`${
                              activeCat === categoryData._id ? "active" : ""
                            }`}
                            onClick={() => {
                              setActiveCat(
                                activeCat === categoryData._id
                                  ? ""
                                  : categoryData._id
                              );
                            }}
                            src={
                              categoryData.image || "images/dummy_category.jpg"
                            }
                            alt={categoryData.title}
                          />
                          <p>{categoryData.title}</p>
                        </div>
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box className={`lookSection`}>
              <Box className="look">
                <Box>
                  <Grid
                    container
                    className="look_container"
                    columnSpacing={2}
                    rowSpacing={4}
                  >
                    {bestSeller?.length > 0 &&
                      bestSeller?.map((item, index) => {
                        return <ProductCard product={item} />;
                      })}
                    {/* Ref for the last product to trigger more load */}
                    <div ref={lastProductRef} style={{ height: "20px" }} />
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};
export default BestSeller;
