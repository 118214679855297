import { TextField } from "@mui/material";

const SDTextField = ({
  title,
  value,
  attrName,
  value_update,
  cls,
  style,
  disable = false,
  required,
  type = "text",
  helperText = `Enter ${title}`,
  warn_status,
  maxLimit,
  validationtype,
}) => {
  return (
    <>
      <div className={`inputField`} style={style}>
        <TextField
          className={cls}
          type={type}
          label={title}
          value={value}
          value_update={value_update}
          disabled={disable}
          onChange={(e) => {
            let newValue = e.target.value;

            if (validationtype === "number") {
              // Allow only numeric input
              if (
                /^\d*$/.test(newValue) &&
                (!maxLimit || newValue.length <= maxLimit)
              ) {
                value_update(attrName, newValue);
              }
            } else if (validationtype === "name") {
              if (
                /^[a-zA-Z]+( [a-zA-Z]+)*$/.test(newValue.trim()) &&
                (!maxLimit || newValue.length <= maxLimit)
              ) {
                value_update(attrName, newValue);
              }

            } else {
              // For text type, enforce the max length if applicable
              if (!maxLimit || newValue.length <= maxLimit) {
                newValue = newValue.replace(/ {2,}/g, " ");
                value_update(attrName, newValue);
              }
            }
          }}
          required={required}
          inputProps={{
            maxLength: maxLimit, // Set the max character limit
          }}
        />
        <span className="error">{warn_status ? helperText : ""}</span>
      </div>
    </>
  );
};

export default SDTextField;
