import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Terms = () => {
  return (
    <>
      <Box className="mainWrapper">
        {/* <Header /> */}
        <Box className="our_blogs privacy">
          <Box>
            <h1>Terms of Service</h1>
            <h2>
              Home/<span> Terms of Service</span>
            </h2>
          </Box>
          <Box className="privacySection">
            <Grid container padding={0}>
              <Grid xs={12} className="mb-6">
                <h3 className="para mb-3">
                  Welcome to Ishnoor.in. By accessing our website and purchasing
                  our products, you agree to comply with and be bound by the
                  following terms and conditions. Please read them carefully.
                </h3>
                <h3 className="para_heading mb-3">1. Acceptance of Terms</h3>
                <h3 className="para mb-3">
                  By using our website, you confirm that you have read,
                  understood, and agree to be bound by these Terms of Service.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">2. Account Registration</h3>
                <h3 className="para mb-3">
                  To access certain features of our website, you may need to
                  create an account. You agree to provide accurate and complete
                  information during the registration process and to update your
                  information as necessary.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">3. Product Information</h3>
                <h3 className="para mb-3">
                  We strive to provide accurate descriptions and images of our
                  products. However, we do not warrant that product descriptions
                  or other content on the site are accurate, complete, reliable,
                  or error-free.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">4. Orders and Payments</h3>
                <h3 className="para mb-3">
                  By placing an order through our website, you agree to pay the
                  total amount due, including applicable taxes and shipping
                  fees. We accept various payment methods, which will be
                  indicated at checkout.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">5. Shipping and Delivery</h3>
                <h3 className="para mb-3">
                  We aim to process and ship orders promptly. However, delivery
                  times may vary based on location and other factors. We are not
                  responsible for delays caused by third-party shipping
                  services.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">6. Returns and Refunds</h3>
                <h3 className="para mb-3">
                  We want you to be satisfied with your purchase. If you are not
                  happy with an item, please refer to our Returns Policy for
                  information on how to return products and request a refund.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">
                  7. Modifications to the Service and Prices
                </h3>
                <h3 className="para mb-3">
                  Prices for our products are subject to change without prior
                  notice. We reserve the right to modify or discontinue the
                  Service (or any part or content thereof) at any time without
                  notice. We shall not be liable to you or any third party for
                  any modifications, price changes, suspensions, or
                  discontinuations of the Service.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">8. Restrictions of Use</h3>
                <h3 className="para mb-3">
                  You must not use our website in any way that could cause
                  damage to it or impair its availability or accessibility.
                  Additionally, you may not engage in any unlawful, fraudulent,
                  or harmful activities in connection with the website.
                  Conducting systematic or automated data collection activities
                  (including but not limited to scraping, data mining, data
                  extraction, and data harvesting) on or relating to our website
                  is strictly prohibited without our explicit written consent.
                  Furthermore, you may not use our website for marketing
                  purposes without our prior written consent.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">9. Restriction of Access</h3>
                <h3 className="para mb-3">
                  Certain areas of our website are restricted. We reserve the
                  right to restrict access to other areas of the website, or
                  even the entire website, at our discretion. We may disable
                  your user ID and password without notice or explanation at any
                  time.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">10. Intellectual Property</h3>
                <h3 className="para mb-3">
                  All content on this site, including text, graphics, logos, and
                  images, is the property of Ishnoor or its licensors and is
                  protected by copyright and other intellectual property laws.
                  You may not reproduce or distribute any content without our
                  express written permission.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">
                  11. Limitation of Liability
                </h3>
                <h3 className="para mb-3">
                  To the fullest extent permitted by law, Ishnoor shall not be
                  liable for any indirect, incidental, or consequential damages
                  arising from your use of the website or your purchase of
                  products.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">12. Compliance with Law</h3>
                <h3 className="para mb-3">
                  These Terms of Use and the services provided by Ishnoor are
                  governed by and in compliance with the applicable laws of the
                  Republic of India.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">13. Contact Us</h3>
                <h3 className="para mb-3">
                  For any questions or concerns about these Terms of Service,
                  please contact us at support@ishnoor.in.
                </h3>
              </Grid>
              <Grid xs={12} className="df mb-6" gap={"12px"}>
                <Box className="con_box df" gap={"12px"}>
                  <Box className="imgdiv">
                    <img
                      loading="eager"
                      src="images/contact_mail.svg"
                      alt="mail"
                    />
                  </Box>
                  <Box>
                    <h2 className="para_heading mb-1">Email</h2>
                    <h2 className="para">support@ishnoor.in</h2>
                  </Box>
                </Box>
                <Box className="con_box df" gap={"12px"}>
                  <Box className="imgdiv">
                    <img
                      loading="eager"
                      src="images/contact_num.svg"
                      alt="num"
                    />
                  </Box>
                  <Box>
                    <h2 className="para_heading mb-1"> Contact us</h2>
                    <h2 className="para">+91 8175918027</h2>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Terms;
