import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Put } from "../../Api/api";
import { URL_CONSTANTS } from "../../Api/ApiUrl";
import "../../commonSCSS/modals.scss";
import { updateAddress } from "../../Redux/Slice/UserSlice";
import SDTextField from "../../Supporting files/SDTextField/SDTextField";

const CreateNewAddress = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState({ tag: "home" });
  const updateMasterState = (attr, value) => {
    setAddress((prev) => {
      return { ...prev, [attr]: value };
    });
  };

  const addAddress = async () => {
    if (
      !address.pin ||
      !address.name ||
      !address.phone ||
      !address.address ||
      !address.district ||
      !address.city ||
      !address.state
    ) {
      return toast.error("Please enter a valid address");
    }

    let payload = {
      pincode: address.pin,
      name: address.name,
      phone: address.phone,
      address: address.address,
      district: address.district,
      city: address.city +","+ address.state,
      tag: address.tag,
    };
    try {
      const response = await Put(
        `${URL_CONSTANTS.addAddress}`,
        JSON.stringify(payload)
      );
      dispatch(updateAddress(response.response));
      setAddress({ tag: "home" });
      toast.success("your address added successfully");
      setOpen(false);
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  return (
    <>
      <Modal open={open} className="modalWrapper">
        <Box className="modalInner">
          <Grid container spacing={3}>
            <Grid xs={9}>
              <h5 className="popup_heading">Add New address</h5>
            </Grid>
            <Grid xs={3} className="close_button">
              <Link onClick={() => setOpen(false)}>
                <CloseIcon />
              </Link>
            </Grid>
            <Grid xs={12} className="divider p-0 mx-3 mb-3"></Grid>
            <Grid xs={12} className="title">
              Contact Details
            </Grid>
            <Grid xs={12} className="title">
              <SDTextField
                title="Name"
                value={address.name}
                attrName={"name"}
                value_update={updateMasterState}
                cls="w-100"
                required={true}
              />
            </Grid>
            <Grid xs={12} className="title">
              <SDTextField
                title="Mobile no"
                value={address.phone}
                attrName={"phone"}
                value_update={updateMasterState}
                cls="w-100"
                required={true}
                maxLimit={10}
                type={"number"}
              />
            </Grid>
            <Grid xs={12} className="title">
              Address
            </Grid>
            <Grid xs={12} className="title">
              <SDTextField
                title="Pin Code"
                value={address.pin}
                attrName={"pin"}
                value_update={updateMasterState}
                cls="w-100"
                required={true}
                maxLimit={6}
                validationtype={"number"}
              />
            </Grid>
            <Grid xs={12} className="title">
              <SDTextField
                title="Address (House no, Building, Street, Area)"
                value={address.address}
                attrName={"address"}
                value_update={updateMasterState}
                cls="w-100"
                required={true}
              />
            </Grid>
            <Grid xs={12} className="title">
              <SDTextField
                title="City"
                value={address.city}
                attrName={"city"}
                value_update={updateMasterState}
                cls="w-100"
                required={true}
              />
            </Grid>
            <Grid xs={6} className="title">
              <SDTextField
                title="District"
                value={address.district}
                attrName={"district"}
                value_update={updateMasterState}
                cls="w-100"
                required={true}
              />
            </Grid>
            <Grid xs={6} className="title">
              <SDTextField
                title="State"
                value={address.state}
                attrName={"state"}
                value_update={updateMasterState}
                cls="w-100"
                required={true}
              />
            </Grid>
            <Grid xs={12} className="title">
              Save Address AS
            </Grid>
            <Grid xs={12} className="ctaBtn py-0">
              <Button
                className={`${
                  address.tag === "home" ? "submitBtn" : "borderBtn"
                } xsm mr-3`}
                onClick={() => {
                  setAddress((prev) => {
                    return { ...prev, ["tag"]: "home" };
                  });
                }}
              >
                Home
              </Button>
              <Button
                className={`${
                  address.tag === "work" ? "submitBtn" : "borderBtn"
                } xsm`}
                onClick={() => {
                  setAddress((prev) => {
                    return { ...prev, ["tag"]: "work" };
                  });
                }}
              >
                Work
              </Button>
            </Grid>

            <Grid xs={12} className="ctaBtn" textAlign={"center"}>
              <Button className="submitBtn" onClick={addAddress}>
                Add Address
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
export default CreateNewAddress;
