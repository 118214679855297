import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";

const ReturnExchange = () => {
  return (
    <>
      <Box className="mainWrapper">
        {/* <Header /> */}
        <Box className="our_blogs privacy">
          <Box>
            <h1>Return & Exchange Policy</h1>
            <h2>
              Home/<span> Return & Exchange Policy</span>
            </h2>
          </Box>
          <Box className="privacySection">
            <Grid container padding={0}>
              <Grid xs={12} className="mb-6">
                <h3 className="para mb-3">
                  At Ishnoor, your satisfaction is our top priority. If you’re
                  not completely happy with your purchase, please follow the
                  steps below to initiate a return or exchange.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">General Policy</h3>
                <h3 className="point mb-3 pl-15">
                  Our return and exchange policy lasts 7 days from the date of
                  delivery. If 7 days have passed since your purchase was
                  delivered, we cannot offer a refund or exchange.
                </h3>
                <h3 className="point mb-3 pl-15">
                  To be eligible for a return, items must be unused, in the same
                  condition as received, and in their original packaging with
                  tags intact.
                </h3>
                <h3 className="point mb-3 pl-15">
                  Please note that there may be a margin of 1-3 inches in the
                  garment size compared to the size chart due to hand
                  embroidery.
                </h3>
                <h3 className="point mb-3 pl-15">
                  Certain items are exempt from returns, including damaged or
                  used items.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">Free Exchange</h3>
                {/* <h3 className="point mb-3 pl-15">
                  To initiate an exchange, please (Return / Exchange request
                  link).
                </h3> */}
                <h3 className="point mb-3 pl-15">
                  Exchange requests must be raised within 7 days of receiving
                  the product.
                </h3>
                <h3 className="point mb-3 pl-15">
                  You will need to provide your order number and the email or
                  registered phone number used for the purchase. You can
                  exchange for the same product in a different size or color, or
                  select a different product from our catalog.
                </h3>
                <h3 className="point mb-3 pl-15">
                  We will arrange for the product to be picked up from your
                  location. The exchange process will begin once we receive the
                  returned item.
                </h3>
                <h3 className="point mb-3 pl-15">
                  The product must be unused and unworn, and requests must be
                  submitted within 7 days of delivery.
                </h3>
                <h3 className="point mb-3 pl-15">
                  We offer a free exchange policy. Please note that items
                  exchanged for the second time will not be eligible for further
                  exchanges.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">Returns</h3>
                <h3 className="para mb-3">
                  If you are unsatisfied with the product delivered, please
                  raise a return request within 7 days of receiving the item. We
                  will arrange for a pickup (please note that reverse pickups
                  may not be available in certain pin codes).
                </h3>
                <h3 className="point mb-3 pl-15">
                  Return requests will only be considered if the customer has
                  accepted an untampered package.
                </h3>
                <h3 className="point mb-3 pl-15">
                  For prepaid returns, the customer is responsible for the
                  shipping charges to send the product back to our warehouse.
                  The full amount will be refunded (for prepaid orders) after
                  deducting standard delivery charges incurred by Ishnoor at the
                  time of the reverse pickup. Refunds will be processed to your
                  bank account within 5-7 days after we receive the returned
                  item.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">
                  Important Points for Returns
                </h3>
                <h3 className="point mb-3 pl-15">
                  Keep the products ready along with the invoice and tags.
                </h3>
                <h3 className="point mb-3 pl-15">
                  Record a short video of the item during pickup to avoid any
                  disputes later.
                </h3>
                <h3 className="point mb-3 pl-15">
                  During sales, prepaid orders are eligible for replacement
                  only; exchanges will not be permitted.
                </h3>
                <h3 className="point mb-3 pl-15">
                  If the desired replacement product is unavailable during a
                  sale, customers can select an equivalent item or pay the
                  difference for a higher-value product.
                </h3>
                <h3 className="point mb-3 pl-15">
                  Returns are not accepted during sales, but exchanges are
                  eligible within 7 days of delivery.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">Refunds (if applicable)</h3>
                <h3 className="para mb-3">
                  Once your return is received and inspected, we will notify you
                  via email about the approval or rejection of your refund. If
                  approved, your refund will be processed, and a credit will
                  automatically be applied to your original payment method
                  within 5-7 working days.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">
                  Late or Missing Refunds (if applicable)
                </h3>
                <h3 className="para mb-3">
                  If you haven’t received your refund yet, please check your
                  bank account again. Then contact your credit card company, as
                  it may take some time before the refund is officially posted.
                  Next, reach out to your bank, as there is often some
                  processing time before a refund is posted. If you’ve done all
                  of this and still haven’t received your refund, please contact
                  us at support@ishnoor.in.
                </h3>
                <h3 className="para mb-3">
                  For any additional queries, feel free to email us at
                  support@ishnoor.in.
                </h3>
              </Grid>
              <Grid xs={12} className="df mb-6" gap={"12px"}>
                <Box className="con_box df" gap={"12px"}>
                  <Box className="imgdiv">
                    <img
                      loading="eager"
                      src="images/contact_mail.svg"
                      alt="mail"
                    />
                  </Box>
                  <Box>
                    <h2 className="para_heading mb-1">Email</h2>
                    <h2 className="para">support@ishnoor.in</h2>
                  </Box>
                </Box>
                <Box className="con_box df" gap={"12px"}>
                  <Box className="imgdiv">
                    <img
                      loading="eager"
                      src="images/contact_num.svg"
                      alt="num"
                    />
                  </Box>
                  <Box>
                    <h2 className="para_heading mb-1"> Contact us</h2>
                    <h2 className="para">+91 8175918027</h2>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default ReturnExchange;
