import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";

const SizeChart = () => {
    return (
      <>
        <Box className="mainWrapper">
          <Box className="our_blogs">
            <Box className="privacySection">
              <Grid container padding={0}>
                <Grid xs={12} className="mb-6">
                  <img src="https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/top.png-1731856019265.png" />
                  <img src="https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/bottom.png-1731856019261.png" />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Footer />
        </Box>
      </>
    );
};

export default SizeChart;