import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import "../Admin/admin.scss";
import { Delete, Get } from "../Api/api";
import CustomTable from "../Supporting files/CustomTable/CustomTable";
import { ADMINURL_CONSTANTS } from "./AdminApiUrl/AdminApiUrl";
const AllCollection = () => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [tableLimit, setTableLimit] = useState();
  const [categories, setCategories] = useState([
    {
      field: "id",
      headerName: "ID",
      minWidth: 250,
      check_status: true,
    },
    {
      field: "collectionName",
      headerName: "Name",
      minWidth: 300,
      check_status: true,
    },
    {
      field: "collectionImage",
      headerName: "Images",
      minWidth: 300,
      check_status: true,
      renderCell: (params) => {
        return (
          <img
            loading="eager"
            src={params?.row?.collectionImage ?? ""}
            alt="Product"
            style={{ width: 100, height: 100 }}
          />
        );
      },
    },
  ]);

  useEffect(() => {
    // Fetch categories from API
    GetAllCollections();
  }, []);
  const GetAllCollections = async (page = 0, pageSize = 25) => {
    try {
      const response = await Get(
        `${ADMINURL_CONSTANTS.GetAllCollections}?page=${
          page + 1
        }&pageSize=${pageSize}`
      );
      const categories = response.response.map((category) => ({
        ...category,
        id: category._id,
      }));
      setCategoryOptions(categories);
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  const updateMasterState = () => {};

  return (
    <Container className="adminWrapper">
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          All Collection
        </Typography>
        <CustomTable
          header_data={categories}
          data={categoryOptions}
          value_update={updateMasterState}
          attrName={"UGTable"}
          BE_filter_code={20}
          totalRecords={totalCount ?? 25}
          refetch_data={GetAllCollections}
          setTableLimit={setTableLimit}
          setTableSkip={setTableSkip}
        />
      </Box>
    </Container>
  );
};

export default AllCollection;
