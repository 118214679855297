export const Role = {
  Admin: "Admin",
  User: "User",
};

export const sizeArray = ["xs", "s", "m", "l", "xl", "xxl"];
export const testimonaldata = [
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/8c3277fb-ee6d-472e-9d3d-a68b327fbee0.jpg-1732443851541.jpg",
    testimonial:
      "The embroidery is so beautiful and detailed, the fabric is also very soft. The thread work is so neat and clean, absolutely loved it. Thankyou for this wonderful article!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/DFUB7202.JPG-1732443851548.JPG",
    testimonial: "Amazing work and quality. Ishnoor never disappoints!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/HFJE6840.JPG-1732443851631.JPG",
    testimonial:
      "Such beautiful packaging and great service. And also thankyou for the jhumkaas as well ☺!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/LLNI8352.JPG-1732443851981.JPG",
    testimonial:
      "The designs are traditional yet so stylish. Perfect for every occasion! Happy with the purchase, will shop more!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/LQTG6283.JPG-1732443851987.JPG",
    testimonial:
      "The fabric is so soft and comfortable. I could wear it all day! Very happy with the quality. Looks amazing!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/ORDG3040.JPG-1732443851992.JPG",
    testimonial:
      "Ishnoor’s Chikankari work is so good, this is my second purchase and as expected it is worth it. So happy with my purchase!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/ORPI5292.JPG-1732443851999.JPG",
    testimonial:
      "The fit was perfect, and the quality exceeded my expectations. Highly recommended!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/IMG_9824.JPG-1732443851750.JPG",
    testimonial:
      "Such unique and elegant pieces. Simple, classy, and elegant. Just what I wanted! Thankyou for this, would love to shop again",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/IMG_9834.JPG-1732443851767.JPG",
    testimonial:
      "The detailing is absolutely stunning. You can tell it’s all handmade with love! The embroidery is so detailed and neat. Loved it!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/IMG_9849.JPG-1732443851789.JPG",
    testimonial:
      "Ishnoor has become my favourite brand for ethnic wear. Can’t wait to order again, great service and timely delivery, and special thanks for the jhumkaas, loved them!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/RPVG0428.JPG-1732443851814.JPG",
    testimonial:
      "The outfit looks even better in person. So happy with my purchase. I received so many compliments on my outfit! Will shop again soon!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/STDH0322.JPG-1732443851845.JPG",
    testimonial:
      "Just received my parcel and the packaging was great. I tried the piece and it is so comfortable, the fabric is very soft and fitting is perfect, just loved it. Thankyou for the jhumkass too, it was unexpected, absolutely loved it",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/22B27299-D935-4095-ADE7-E30D4110CAB9.jpg-1732443851876.jpg",
    testimonial:
      "Can’t wait to shop again from you guys, the kurti is perfect. Thankyou for the free jhumkass as well",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/AUKM3606.JPG-1732443851885.JPG",
    testimonial:
      "The outfit I bought from Ishnoor was perfect for my event. It was traditional yet modern, and the fit was spot on.",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/DAEM4617.JPG-1732443851904.JPG",
    testimonial:
      "The handmade work makes every piece so special. The customer service was excellent, and the outfit was even better than I expected. Thank you for the beautiful work!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/EMPM6041.JPG-1732443851914.JPG",
    testimonial:
      "Such a hassle-free shopping experience. Thank you for the amazing service, will definitely shop again from you guys",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/FVLE7853.JPG-1732443851932.JPG",
    testimonial:
      "The quality is excellent, and I felt so comfortable wearing the outfit all day. Truly worth it!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/JQAZ5633.JPG-1732443851953.JPG",
    testimonial:
      "My outfit from Ishnoor was perfect in every way. The fabric, the embroidery, and the fit were all amazing, highly recommended.",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/LLNI8352.JPG-1732443851981.JPG",
    testimonial:
      "Ishnoor’s pieces have such a personal and handmade touch. The outfit I bought feels so special, and I’ve already received so many compliments on it!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/LQTG6283.JPG-1732443851987.JPG",
    testimonial:
      "Nice dress and great service, it was my first order from you guys and you nailed it. Keep it up, will shop again soon!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/ORDG3040.JPG-1732443851992.JPG",
    testimonial:
      "My experience with Ishnoor has been fantastic from start to finish. The customer service was also amazing, and the packaging was beautiful. Thank you!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/ORPI5292.JPG-1732443851999.JPG",
    testimonial:
      "I was amazed by the quality and finishing of the product. The handmade detailing is so fine, and the outfit I chose was just perfect for my occasion. Thank you for this article!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/QIRL7207.JPG-1732443852004.JPG",
    testimonial:
      "I was blown away by the beautiful Chikankari embroidery and the soft, high-quality fabric. The outfit fit me perfectly and looked even better than I imagined. Worthy every penny, would definitely shop again!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/QIWB2019.JPG-1732443852017.JPG",
    testimonial:
      "I’m really impressed with the quality and comfort of my outfit. The embroidery is beautiful and well done!",
  },
  {
    url: "https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/UBZX1177.JPG-1732443852042.JPG",
    testimonial:
      "I’m so happy with my purchase from Ishnoor. The outfit is beautiful, and the embroidery is perfect.",
  },
];
