//const BASE_URL = process.env.REACT_APP_BACKEND_URL;
// const BASE_URL = "http://ec2-3-27-124-225.ap-southeast-2.compute.amazonaws.com";

const BASE_URL = "https://ishnoor.in/api";

export const ADMINURL_CONSTANTS = {
  GetAllCategory: `${BASE_URL}/categories`,
  GetAllCollections: `${BASE_URL}/categories/collection`,
  AddProduct: `${BASE_URL}/products`,
  UpdateProduct: `${BASE_URL}/products`,
  GetAllProduct: `${BASE_URL}/products`,
  ADDCategory: `${BASE_URL}/categories`,
  AddCollection: `${BASE_URL}/categories/add-collection`,
  postBlog: `${BASE_URL}/blogs`,
  getAllBlogs: `${BASE_URL}/blogs`,
  updateBlogs: `${BASE_URL}/blogs`,
  deleteBlogs: `${BASE_URL}/blogs`,
  signup: `${BASE_URL}/admin/signup`,
  login: `${BASE_URL}/admin/login`,
  GetAllOrders: `${BASE_URL}/orders`,
  GetAllUsers: `${BASE_URL}/users`,
  orderDetails: `${BASE_URL}/orders/detail/`,
  updateOrderstatus: `${BASE_URL}/orders/order-status/`,
  updateReturntatus: `${BASE_URL}/orders/update/return-order-status/`,
  activeOrInactiveProduct: `${BASE_URL}/products/`,
  activeOrInactiveCategory: `${BASE_URL}/categories/`,
  contactList: `${BASE_URL}/auth/contact-list`,
  returnRequest: `${BASE_URL}/orders/list-return-order`,
};
